<template>
  <div class="companyAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar
      :title="'单位物联设备详情'"
      v-watermark
    ></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-tabs
        v-model="active"
        sticky
        @change="tabChange"
      >
        <van-tab title="设备信息">
          <div
            class="scrollBox"
            ref="scrollBox"
            @scroll="onScroll"
          >
            <van-collapse
              v-model="activeNames"
              accordion
            >
              <van-collapse-item
                title="基本信息"
                name="1"
              >
                <van-cell
                  center
                  title="设备ID:"
                  class="more"
                  :value="CompanyInternetInfo.code || '--'"
                />
                <van-cell
                  center
                  title="状态:"
                >
                  <template #default>
                    <span
                      style="color: #00a854"
                      v-if="CompanyInternetInfo.status === 0"
                    >在用</span>
                    <span
                      style="color: #1989fa"
                      v-else-if="CompanyInternetInfo.status === 1"
                    >拆回</span><span
                      style="color: #f59a23"
                      v-else-if="CompanyInternetInfo.status === 2"
                    >维修</span><span
                      style="color: #d9001b"
                      v-else-if="CompanyInternetInfo.status === 3"
                    >报废</span>
                    <span v-else-if="CompanyInternetInfo.status === 4">遗失</span>
                    <span v-else>--</span>
                  </template>
                </van-cell>
                <van-cell
                  center
                  title="通讯方式:"
                  :value="CompanyInternetInfo.communicationMode || '--'"
                />
                <van-cell
                  center
                  title="网络模式:"
                  :value="CompanyInternetInfo.useCommunicationType || '--'"
                />
                <van-cell
                  center
                  title="设备品牌:"
                  :value="CompanyInternetInfo?.equipment?.brand?.name || '--'"
                />
                <van-cell
                  center
                  title="设备类型:"
                  :value="CompanyInternetInfo?.equipment?.natureName || '--'"
                />
                <van-cell
                  center
                  title="设备型号:"
                >
                  <template #default>
                    <p
                      :style="{ color: '#1990ff' }"
                      v-if="CompanyInternetInfo?.equipment?.name"
                      @click="equipmentClick(CompanyInternetInfo.equipment.id)"
                    >
                      {{ CompanyInternetInfo.equipment.name }}
                    </p>
                    <p v-else>--</p>
                  </template>
                </van-cell>
                <van-cell
                  center
                  title="系统类型:"
                  :value="CompanyInternetInfo?.equipment?.systemType
                    ? CompanyInternetInfo.equipment.systemType.toString()
                    : '--'
                    "
                />
              </van-collapse-item>
              <van-collapse-item
                :title="`卡号信息${item.operators ? ' - ' + item.operators : ''
                  }`"
                :name="item.id"
                v-for="(item, index) in CompanyInternetInfo.openLogs"
                :key="index"
                :id="item.id"
              >
                <van-cell
                  center
                  title="通讯卡号:"
                >
                  <template #default>
                    <p
                      :style="{
                        color: '#1990ff',
                      }"
                      v-if="item.cardNumber"
                      @click="cardNumberClick(item.id)"
                    >
                      {{ item.cardNumber }}
                    </p>
                    <p v-else>--</p>
                  </template>
                </van-cell>
                <van-cell
                  center
                  title="运营商:"
                  class="more"
                  :value="item.operators || '--'"
                />
                <van-cell
                  center
                  title="通讯类型:"
                  class="more"
                  :value="item.communicationType || '--'"
                />
              </van-collapse-item>
              <van-collapse-item
                title="安装信息"
                name="2"
              >
                <van-cell
                  center
                  title="测点名称:"
                  :value="CompanyInternetInfo?.install?.name || '--'"
                />
                <van-cell
                  center
                  title="单位编号:"
                >
                  <template #default>
                    <p v-if="CompanyInternetInfo?.install?.company
                    ">
                      <span
                        v-for="(
                          companyItem, companyIndex
                        ) in CompanyInternetInfo.install.company"
                        :key="companyIndex"
                      >{{ companyItem.code }}
                      </span>
                    </p>
                    <p v-else>--</p>
                  </template>
                </van-cell>
                <van-cell
                  center
                  title="单位名称:"
                >
                  <template #default>
                    <div
                      :style="{
                        color: '#1990ff',
                      }"
                      v-if="CompanyInternetInfo?.install?.company"
                    >
                      <p
                        v-for="(
                          companyItem, companyIndex
                        ) in CompanyInternetInfo.install.company"
                        :key="companyIndex"
                        @click="companyClick(companyItem.id)"
                      >
                        {{ companyItem.name }}
                      </p>
                    </div>
                    <p v-else>--</p>
                  </template>
                </van-cell>
                <van-cell
                  center
                  title="安装地址:"
                >
                  <template #default>
                    <div
                      style="color: #1990ff"
                      @click="
                        addressHandler(
                          CompanyInternetInfo.install.location.address
                        )
                        "
                      v-if="CompanyInternetInfo?.install?.location?.address
                      "
                      class="ellipsis"
                    >
                      {{ CompanyInternetInfo.install.location.address }}
                    </div>
                    <div v-else>--</div>
                  </template>
                </van-cell>
                <van-cell
                  center
                  title="安装位置:"
                  :value="(CompanyInternetInfo.install &&
                    CompanyInternetInfo.install.installationPosition) ||
                    '--'
                    "
                />
                <van-cell
                  center
                  title="更新时间:"
                  :value="(CompanyInternetInfo.install &&
                    CompanyInternetInfo.install.updateDatetime) | dateFormat
                    "
                />
                <userJump
                  title="更新人:"
                  :userList="CompanyInternetInfo?.install?.updateBy"
                ></userJump>
              </van-collapse-item>
              <van-collapse-item
                title="图片信息"
                name="3"
              >
                <van-cell
                  center
                  title="设备图片预览:"
                  is-link
                  @click="picClick"
                >
                  <template #default>
                    <div v-if="CompanyInternetInfo.enclosureFiles &&
                      CompanyInternetInfo.enclosureFiles.length
                    ">
                      <span style="color: #1990ff">
                        {{ CompanyInternetInfo.enclosureFiles.length }} </span>张
                    </div>
                    <p v-else>0张</p>
                  </template>
                </van-cell>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-tab>
        <van-tab title="运营信息">
          <div
            class="scrollBox"
            ref="scrollBoxYyInfo"
            @scroll="onScrollYyInfo"
          >
            <van-tabs
              v-model="activeInfo"
              sticky
              type="card"
              color="#1989fa"
              class="myTabs"
            >
              <van-tab :title="`开通记录${CompanyInternetInfo.operators &&
                CompanyInternetInfo.operators.length
                ? `(${CompanyInternetInfo.operators.length})`
                : ''
                }`">
                <van-collapse
                  v-model="activeOperators"
                  accordion
                  v-if="CompanyInternetInfo.operators &&
                    CompanyInternetInfo.operators.length
                  "
                >
                  <van-collapse-item
                    :title="`开通记录${item.operators ? ' - ' + item.operators : ''
                      }`"
                    :name="item.id"
                    v-for="(item, index) in CompanyInternetInfo.operators"
                    :key="index"
                    :id="item.id"
                  >
                    <van-cell
                      center
                      title="单位名称:"
                    >
                      <template #default>
                        <div
                          :style="{
                            color: '#1990ff',
                          }"
                          v-if="item.company"
                        >
                          <p
                            v-for="(companyItem, companyIndex) in item.company"
                            :key="companyIndex"
                            @click="companyClick(companyItem.id)"
                          >
                            {{ companyItem.name }}
                          </p>
                        </div>
                        <p v-else>--</p>
                      </template>
                    </van-cell>
                    <van-cell
                      center
                      title="通讯卡号:"
                    >
                      <template #default>
                        <p
                          :style="{
                            color: '#1990ff',
                          }"
                          v-if="item.cardNumber && item.cardNumber.number"
                          @click="cardNumberClick(item.cardNumber.id)"
                        >
                          {{ item.cardNumber.number }}
                        </p>
                        <p v-else>--</p>
                      </template>
                    </van-cell>
                    <van-cell
                      center
                      title="运营商:"
                      class="more"
                      :value="item.operators || '--'"
                    />
                    <van-cell
                      center
                      title="通讯类型:"
                      class="more"
                      :value="item.communicationType || '--'"
                    />
                    <van-cell
                      center
                      title="开通日期:"
                      :value="item.openDatetime | openYmdFormat"
                    />
                    <userJump
                      title="开通人员:"
                      :userList="item.openPeoples"
                    ></userJump>
                    <userJump
                      title="检查人员:"
                      :userList="item.checkPeople"
                    ></userJump>
                    <van-cell
                      center
                      title="更新时间:"
                      :value="item.updateDatetime | dateFormat"
                    />
                    <userJump
                      title="更新人:"
                      :userList="item.updateBy"
                    ></userJump>
                  </van-collapse-item>
                </van-collapse>
                <div
                  class="empty"
                  v-else
                >
                  <van-empty
                    image="search"
                    description="暂无相关开通记录"
                  />
                </div>
              </van-tab>
              <van-tab :title="`换卡记录${CompanyInternetInfo.cards && CompanyInternetInfo.cards.length
                ? `(${CompanyInternetInfo.cards.length})`
                : ''
                }`">
                <van-collapse
                  v-model="activeCards"
                  accordion
                  v-if="CompanyInternetInfo.cards &&
                    CompanyInternetInfo.cards.length
                  "
                >
                  <van-collapse-item
                    :title="item.changeCardTime | changeCardTimeFormat"
                    :name="item.id"
                    v-for="(item, index) in CompanyInternetInfo.cards"
                    :key="index"
                    :id="item.id"
                  >
                    <van-cell
                      center
                      title="通讯卡号:"
                    >
                      <template #default>
                        <p
                          :style="{
                            color: '#1990ff',
                          }"
                          v-if="item.cardNumber && item.cardNumber.number"
                          @click="cardNumberClick(item.cardNumber.id)"
                        >
                          {{ item.cardNumber.number }}
                        </p>
                        <p v-else>--</p>
                      </template>
                    </van-cell>
                    <van-cell
                      center
                      title="运营商:"
                      class="more"
                      :value="item.operators || '--'"
                    />
                    <van-cell
                      center
                      title="通讯类型:"
                      class="more"
                      :value="item.communicationType || '--'"
                    />
                    <van-cell
                      center
                      title="换卡原因:"
                      class="more"
                      :value="item.changeCardReason || '--'"
                    />
                    <van-cell
                      center
                      title="换卡日期:"
                      :value="item.changeCardTime | openYmdFormat"
                    />
                    <userJump
                      title="换卡人员:"
                      :userList="item.changeCardPeople"
                    ></userJump>
                    <van-cell
                      center
                      title="更新时间:"
                      :value="item.updateDatetime | dateFormat"
                    />
                    <userJump
                      title="更新人:"
                      :userList="item.updateBy"
                    ></userJump>
                  </van-collapse-item>
                </van-collapse>
                <div
                  class="empty"
                  v-else
                >
                  <van-empty
                    image="search"
                    description="暂无相关换卡记录"
                  />
                </div>
              </van-tab>
            </van-tabs>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getCompanyInternetInfo } from '@/api/companyInternet'
import dayjs from 'dayjs'
import { Dialog, ImagePreview } from 'vant'
import wx from 'weixin-js-sdk'
import { mapState } from 'vuex'
import index from '@/mixins'

export default {
  name: 'companyAddressInfo',
  mixins: [index],
  data() {
    return {
      activeNames: '1',
      CompanyInternetInfo: {},
      loadingShow: false,
      active: 0,
      activeInfo: 0,
      activeOperators: 0,
      activeCards: 0,
      top: 0,
      topYyInfo: 0
      // showPosition: false,
      // positionActions: [{ name: '高德地图' }, { name: '百度地图' }]
    }
  },
  activated() {
    switch (this.active) {
      case 0:
        if (this.$refs.scrollBox) {
          this.$refs.scrollBox.scrollTop = this.top
        }
        break
      case 1:
        if (this.$refs.scrollBoxYyInfo) {
          this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
        }
        break
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.path.includes('/workTable/companyInternetResult') ||
      from.path.includes('/workTable/companyInternetAddressBook') ||
      from.path.includes('/workTable/companyInternetAddAddressBook') ||
      from.path.includes('/workTable/companyInternetAddResult') ||
      from.path.includes('message/systemMessage/1') ||
      to.params.Refresh
    ) {
      next(async (vm) => {
        vm.activeNames = '1'
        vm.activeOperators = 0
        vm.activeCards = 0
        vm.CompanyInternetInfo = {}
        vm.activeInfo = 0
        vm.top = 0
        vm.topYyInfo = 0
        vm.loadingShow = true
        try {
          const { data } = await getCompanyInternetInfo(vm.$route.params.id)
          if (data.data) {
            vm.CompanyInternetInfo = data.data
            vm.active = 0
            if (data.data.operators.length) {
              vm.activeOperators = data.data.operators[0].id
            }
            if (data.data.cards.length) {
              vm.activeCards = data.data.cards[0].id
            }
            if (vm.$route.query.type === '15' && vm.$route.query.openId) {
              vm.active = 1
              vm.activeInfo = 0
              if (data.data.operators?.map(item => item.id).includes(vm.$route.query.openId)) {
                vm.activeOperators = vm.$route.query.openId
                vm.$nextTick(() => {
                  document
                    .getElementById(vm.$route.query.openId)
                    .scrollIntoView()
                })
              }
            }
            if (vm.$route.query.type === '16' && vm.$route.query.changeCardId) {
              vm.active = 1
              vm.activeInfo = 1
              if (data.data.cards?.map(item => item.id).includes(vm.$route.query.changeCardId)) {
                vm.activeCards = vm.$route.query.changeCardId
                vm.$nextTick(() => {
                  document
                    .getElementById(vm.$route.query.changeCardId)
                    .scrollIntoView()
                })
              }
            }
            wx.onMenuShareAppMessage({
              title: vm.CompanyInternetInfo?.install?.name, // 分享标题
              desc: `设备ID:${vm.CompanyInternetInfo.code ? vm.CompanyInternetInfo.code : '--'
                }\n单位名称:${vm.CompanyInternetInfo?.install?.company
                  ? vm.CompanyInternetInfo?.install?.company?.map(item => item.name).toString()
                  : '--'
                }\n设备型号:${vm.CompanyInternetInfo.equipmentType
                  ? vm.CompanyInternetInfo.equipmentType
                  : '--'
                }`, // 分享描述
              link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
              imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
              enableIdTrans: 1, // 是否开启id转译，不填默认为0
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              error: function (res) {
                alert('暂无分享权限')
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              }
            })
            vm.loadingShow = false
          } else {
            vm.loadingShow = false
            if (data?.status === 'E00030007') {
              vm.$toast.fail({
                message: '暂无相应权限,请联系管理员!',
                duration: 500,
                onOpened: () => {
                  vm.$router.replace('/')
                }
              })
              return
            }
            vm.$toast.fail({
              message: '该单位物联设备已被删除!!',
              duration: 500
            })
          }
        } catch (error) {
          vm.loadingShow = false
          if (error?.response?.status === 401) {
            return
          }
          if (error.message.includes('timeout')) {
            vm.$toast.fail({
              message: '网络不好，请稍后再试!',
              duration: 500
            })
            return
          }
          if (error?.response?.data?.status === 'E00030007') {
            vm.$toast.fail({
              message: '暂无相应权限,请联系管理员!',
              duration: 500,
              onOpened: () => {
                vm.$router.replace('/')
              }
            })
            return
          }
          vm.$toast.fail({
            message: '当前网络异常，请稍后再试!!',
            duration: 500
          })
        }
      })
    } else {
      next(async (vm) => {
        if (JSON.stringify(vm.CompanyInternetInfo) === '{}') {
          vm.loadingShow = true
          try {
            const { data } = await getCompanyInternetInfo(vm.$route.params.id)
            if (data.data) {
              vm.CompanyInternetInfo = data.data
              if (data.data.operators.length) {
                vm.activeOperators = data.data.operators[0].id
              }
              if (data.data.cards.length) {
                vm.activeCards = data.data.cards[0].id
              }
              if (vm.$route.query.type === '15' && vm.$route.query.openId) {
                vm.active = 1
                vm.activeInfo = 0
                if (data.data.operators?.map(item => item.id).includes(vm.$route.query.openId)) {
                  vm.activeOperators = vm.$route.query.openId
                  vm.$nextTick(() => {
                    document
                      .getElementById(vm.$route.query.openId)
                      .scrollIntoView()
                  })
                }
              }
              if (
                vm.$route.query.type === '16' &&
                vm.$route.query.changeCardId
              ) {
                vm.active = 1
                vm.activeInfo = 1
                if (data.data.cards?.map(item => item.id).includes(vm.$route.query.changeCardId)) {
                  vm.activeCards = vm.$route.query.changeCardId
                  vm.$nextTick(() => {
                    document
                      .getElementById(vm.$route.query.changeCardId)
                      .scrollIntoView()
                  })
                }
              }
              wx.onMenuShareAppMessage({
                title: vm.CompanyInternetInfo?.install?.name, // 分享标题
                desc: `设备ID:${vm.CompanyInternetInfo.code
                  ? vm.CompanyInternetInfo.code
                  : '--'
                  }\n单位名称:${vm.CompanyInternetInfo?.install?.company
                    ? vm.CompanyInternetInfo?.install?.company?.map(item => item.name).toString()
                    : '--'
                  }\n设备型号:${vm.CompanyInternetInfo.equipmentType
                    ? vm.CompanyInternetInfo.equipmentType
                    : '--'
                  }`, // 分享描述
                link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
                enableIdTrans: 1, // 是否开启id转译，不填默认为0
                success: function () {
                  // 用户确认分享后执行的回调函数
                },
                error: function (res) {
                  alert('暂无分享权限')
                },
                cancel: function () {
                  // 用户取消分享后执行的回调函数
                }
              })
              vm.loadingShow = false
            } else {
              vm.loadingShow = false
              if (data?.status === 'E00030007') {
                vm.$toast.fail({
                  message: '暂无相应权限,请联系管理员!',
                  duration: 500,
                  onOpened: () => {
                    vm.$router.replace('/')
                  }
                })
                return
              }
              vm.$toast.fail({
                message: '该单位物联设备已被删除!!',
                duration: 500
              })
            }
          } catch (error) {
            vm.loadingShow = false
            if (error?.response?.status === 401) {
              return
            }
            if (error.message.includes('timeout')) {
              vm.$toast.fail({
                message: '网络不好，请稍后再试!',
                duration: 500
              })
              return
            }
            if (error?.response?.data?.status === 'E00030007') {
              vm.$toast.fail({
                message: '暂无相应权限,请联系管理员!',
                duration: 500,
                onOpened: () => {
                  vm.$router.replace('/')
                }
              })
              return
            }
            vm.$toast.fail({
              message: '当前网络异常，请稍后再试!!',
              duration: 500
            })
          }
        }
      })
    }
  },
  computed: {
    ...mapState('user', ['jurisdiction'])
  },
  methods: {
    // 单位跳转
    companyClick(id) {
      if (id) {
        this.$router.push({
          name: 'companyAddressInfo',
          params: {
            Refresh: true,
            id: id
          }
        })
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },
    // 物联型号跳转
    equipmentClick(id) {
      this.$router.push(`/workTable/internetModelAddressInfo/${id}`)
    },

    // 地址弹框
    addressHandler(text) {
      this.addressClickTo(
        this.CompanyInternetInfo.install.location.address,
        this.CompanyInternetInfo?.install?.company[0].name || this.CompanyInternetInfo.install.location.address
      )
      Dialog.confirm({
        title: '安装地址',
        message: text,
        confirmButtonColor: '#1989FA',
        confirmButtonText: '去定位',
        beforeClose: this.beforeClose
      })
        .then(() => {
          // on close
        })
        .catch(() => {
          // on cancel
        })
    },
    // 预览图片
    picClick() {
      if (
        this.CompanyInternetInfo.enclosureFiles &&
        this.CompanyInternetInfo.enclosureFiles.length
      ) {
        const picArr = this.CompanyInternetInfo.enclosureFiles.map((item) => {
          return '/api/file' + item.largePath
        })
        ImagePreview({
          images: [...picArr],
          closeable: true
        })
      }
    },
    // 通讯卡号点击
    cardNumberClick(id) {
      if (id && this.jurisdiction.includes('cardNumberAddressBook:info')) {
        this.$router.push(`/workTable/cardNumberAddressInfo/${id}`)
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },

    // tabs标签切换
    tabChange(name) {
      switch (name) {
        case 0:
          if (this.$refs.scrollBox) {
            this.$nextTick(() => {
              this.$refs.scrollBox.scrollTop = this.top
            })
          }
          break
        case 1:
          if (this.$refs.scrollBoxYyInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
            })
          }
          break
      }
    },
    // 设备信息内容滚动
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 运营信息滚动
    onScrollYyInfo(e) {
      this.topYyInfo = e.target.scrollTop
    }
  },
  filters: {
    // 换卡记录标题格式化
    changeCardTimeFormat(val) {
      if (val) {
        return '换卡记录 - ' + dayjs(val).format('YYYYMMDD')
      } else {
        return '换卡记录'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.companyAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .more {
      .van-cell__value {
        flex: none;
      }
    }

    .myTabs {
      height: 100%;

      .van-tabs__content {
        height: calc(100% - 43.98px);

        .van-tab__pane {
          height: 100%;
        }
      }
    }

    .empty {
      height: 100%;
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .transform {
      transform: rotate(180deg);
    }

    .info {
      height: calc(100% - 46px);
      overflow: hidden;

      .van-tabs__line {
        background-color: #1989fa;
      }

      .van-sticky--fixed {
        top: 46px;
      }

      >.van-tabs {
        height: 100%;

        >.van-tabs__content {
          height: calc(100% - 44px);
          overflow: hidden;

          >.van-tab__pane {
            height: 100%;

            .scrollBox {
              height: 100%;
              overflow: auto;
            }
          }

          >.van-tab__pane {
            &:nth-child(2) {
              .van-tabs__wrap {
                background-color: white;
              }
            }
          }

          .van-collapse {
            background-color: transparent;

            .van-collapse-item {
              background-color: transparent;

              .van-collapse-item__title {
                background-color: white;
                font-weight: 700;
              }

              .van-collapse-item__wrapper {
                background-color: transparent;

                .van-collapse-item__content {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
